<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="attendance-container common-padding">
        <div class="title">
            客服考勤
            <div>
                <el-button class="common-screen-btn" type="primary" @click="() => { $router.push('/service/shift-management') }">班次管理</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { $router.push('/service/service-scheduling') }">客服排班</el-button>
            </div>
        </div>
        <div class="head-main">
            <div class="common-Summary-container">
                <div class="Summary-item" style="align-items: center;width: auto">
                    <el-date-picker class="topHeadDate" v-model="params.reportDate" @change="topHead" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                </div>
                <div class="Summary-item">
                <div class="item-title">桌面端交班下班数</div>
                <div @click="() => { collect(
                        'desktopNum',
                        '桌面端交班下班数',
                        [
                            { name: '客服名称', type: 'input', modelKey: 'nickName' }
                        ],
                        [
                            { name: '客服名称', colProp: ['nickName'] },
                            { name: '交班时间', colProp: ['endTime'] },
                        ],
                        '0',
                        '1'
                    )}" class="item-num finger">{{ dateRange.desktopNum || "0" }}</div>
                </div>
                <div class="Summary-item SummaryItem-border">
                    <div class="item-title">移动端交班下班数</div>
                    <div @click="() => { collect(
                            'MTNum',
                            '移动端交班下班数',
                            [
                                { name: '客服名称', type: 'input', modelKey: 'nickName' }
                            ],
                            [
                                { name: '客服名称', colProp: ['nickName'] },
                                { name: '交班时间', colProp: ['endTime'] },
                            ],
                            '0',
                            '2'
                        )}" class="item-num finger">{{ dateRange.MTNum || "0" }}
                    </div>
                </div>
                <div class="Summary-item">
                    <div class="item-title">未交班下班数</div>
                    <div @click="() => { collect(
                            'notNum',
                            '未交班下班数',
                            [
                                { name: '客服名称', type: 'input', modelKey: 'nickName' }
                            ],
                            [
                                { name: '客服名称', colProp: ['nickName'] },
                                { name: '接班时间', colProp: ['createTime'] },
                            ],
                            '1'
                        )}" class="item-num finger">{{ dateRange.notNum || "0" }}
                    </div>
                </div>
            </div>
        </div>
        <div class="calculation-screen-container">
            <div class="common-input-container">
                <span>所属部门:</span>
                <a-cascader class="common-screen-input" :showSearch="true" v-model="params.userDepartId" :options="selectDepart" :fieldNames="{ label: 'name', value: 'id', children: 'child' }" placeholder="请选择" change-on-select @change="commonFun" />
            </div>
            <div class="common-input-container">
                <span>客服名称:</span>
                <el-input class="common-screen-input" v-model="params.nickName" placeholder="客服名称" clearable></el-input>
            </div>
            <el-button class="common-screen-btn" type="primary" @click="() => commonFun()" >查 询</el-button>
            <el-button class="common-screen-btn" plain @click="() => resetBtn()">重 置</el-button>
        </div>
        <div class="grey-line"></div>
        <div class="calculation-screen-container">
            <el-date-picker v-show="tableRadio == '按周'" v-model="tableDate" :picker-options="{ firstDayOfWeek: 1 }" type="week" format="yyyy 第 WW 周" placeholder="选择周" @change="(e) => onchangeDate(e, 'W')" ></el-date-picker>
            <el-date-picker v-show="tableRadio == '按月'" v-model="tableDate" type="month" @change="(e) => onchangeDate(e, 'M')" placeholder="选择月" ></el-date-picker>
            <el-radio-group class="date" v-model="tableRadio" @change="isMonthWeekFun">
                <el-radio-button label="按周"></el-radio-button>
                <el-radio-button label="按月"></el-radio-button>
            </el-radio-group>
        </div>
        <div style="padding: 0 16px">
            <el-table :cell-style="testClass"  v-if="tableRadio == '按周'" :data="tableData" border style="width: 100%">
                <el-table-column prop="nickName" label="客服姓名" width="120"></el-table-column>
                <el-table-column prop="ShouldAttendanceDay" label="应出勤天数" width="120"></el-table-column>
                <el-table-column prop="actualDay" label="实际出勤" width="120"></el-table-column>
                <el-table-column prop="attendance" label="出勤率" width="120"></el-table-column>
                <el-table-column prop="allWorkingTime" label="累计工时" width="120">
                    <template slot-scope="scope">
                        <span>{{scope.row.allWorkingTime || 0}}h</span>
                    </template>
                </el-table-column>
                <el-table-column prop="anomalyDay" label="出勤异常" width="120">
                    <template slot-scope="scope">
                        <span>{{scope.row.anomalyDay || 0}}次</span>
                    </template>
                </el-table-column>
                <el-table-column v-for="(item, wIndex) in arrWeekDate" :key="item.week + '' + item.date" :label="`${item.week}`">
                    <el-table-column :prop="`schedulingRes[${wIndex}]`" :label="item.date">
                        <template slot-scope="scope">
                            <div>{{scope.row.schedulingRes[wIndex]}}</div>
                            <!-- <div :style="{color: abnormalFun(scope.row.schedulingRes[wIndex]) ? '#e83636': '#333'}">{{scope.row.schedulingRes[wIndex]}}</div> -->
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
            <el-table :cell-style="testClass" v-if="tableRadio == '按月'" :data="tableData" border style="width: 100%">
                <el-table-column prop="nickName" label="客服姓名" width="120"></el-table-column>
                <el-table-column prop="ShouldAttendanceDay" label="应出勤天数" width="120"></el-table-column>
                <el-table-column prop="actualDay" label="实际出勤" width="120"></el-table-column>
                <el-table-column prop="attendance" label="出勤率" width="120"></el-table-column>
                <el-table-column prop="allWorkingTime" label="累计工时" width="120">
                    <template slot-scope="scope">
                        <span>{{scope.row.allWorkingTime || 0}}h</span>
                    </template>
                </el-table-column>
                <el-table-column prop="anomalyDay" label="出勤异常" width="120">
                    <template slot-scope="scope">
                        <span>{{scope.row.anomalyDay || 0}}次</span>
                    </template>
                </el-table-column>
                <el-table-column v-for="(item, mIndex) in arrWeekMonth" :key="item.week + '' + item.date" :label="`${item.week}`" width="120">
                    <el-table-column :prop="`schedulingRes[${mIndex}]`" :label="item.date" width="120">
                        <template slot-scope="scope">
                            <!-- <div :style="{color: abnormalFun(scope.row.schedulingRes[mIndex]) ? '#e83636': '#666'}">{{scope.row.schedulingRes[mIndex]}}</div> -->
                            <div>{{scope.row.schedulingRes[mIndex]}}</div>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
            <br/>
            <el-pagination
            @size-change="(e) => pageFun(e, 'pageSize')"
            @current-change="(e) => pageFun(e)"
            style="text-align: center"
            :page-sizes="pagination.pageSizes"
            :page-size="params.pageSize"
            :current-page="params.page"
            layout="total, prev, pager, next,sizes, jumper"
            :total="pagination.total">
            </el-pagination>
        </div>
         <!-- 头部报表弹 -->
        <head-dialog
        v-if="commonSumVisible"
        ref="commonSumDialog"
        :methodFuc="getAttendanceHead"
        :commonSumVisible="commonSumVisible"
        @handleClose="sumVisibleClose"
        ></head-dialog>
    </div>
</template>
<script>
import { getWeekMonthInfo, getWeekDateInfo, Config, DateTransform } from "../../utils/index.js"
import { getAttendanceList, getAttendanceHead } from "../../service/portrait.js"
import { commonDepartAll } from "../../service/common.js"
import { Cascader } from 'ant-design-vue';
import { mapState } from "vuex";
import HeadDialog from "./children/headDialog.vue"
export default {
    components: { aCascader: Cascader, HeadDialog },
    data () {
        return {
            getAttendanceHead,
            loading: false,
            companyId: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司id
            commonRadio: 1, // 时间控件默认选中
            params: { // 列表筛选参数
                startDate: '',
                endDate: '',
                page: 1,
                pageSize: 10,
                userDepartId: [],
                nickName: '',
                reportDate: DateTransform(new Date()),
            },
            pagination: {
                total: 0,
                pageSizes: [5, 10, 20, 50, 100]
            },
            tableData: [], // 表格数据
            headWeekDate: [], // 获取表头时间
            selectDepart: [],
            tableRadio: "按周",
            tableDate: new Date(), // 默认月份 new Data()
            arrWeekMonth: getWeekMonthInfo(new Date()),
            arrWeekDate: getWeekDateInfo(new Date()),
            commonSumVisible: false,
            dateRange: {}, // 头部弹窗数据
        }
    },
    computed: {
        ...mapState(["comId"])
    },
    created () {
        this.getComId()
        this.topHead();
    },
    methods: {
        async topHead () { // 头部数据
            let params = {
                startDate: this.params.reportDate,
                endDate: this.params.reportDate,
                companyId: this.companyId
            } 
            let { data } = await getAttendanceHead(params)
            this.dateRange = {
                notNum: data.notNum,
                desktopNum: data.desktopNum,
                MTNum: data.MTNum
            }
        },
        sumVisibleClose () {// 数据汇总弹窗
            this.commonSumVisible = false
        },
        collect(type, name, screen, arr, notClock, sourceType) { // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据;notClock:0排班，1未排班;sourceType:1桌面端，2移动端
            this.commonSumVisible = true;
            this.$nextTick(() => {
                this.$refs.commonSumDialog.getAllData(type, name, screen, arr, this.companyId,[this.params.reportDate, this.params.reportDate], notClock, sourceType)
            }) 
        },
        testClass ({row, columnIndex, column}) { // 表格-单元格样式
            let objStyle = { background: '#fff', color: '#333' }
            if (columnIndex >= 6) {
                let cellText = row.schedulingRes[columnIndex - 6]
                console.log(row)
                let isGrey = (new Date(column.label)).getTime() > (new Date(DateTransform(new Date()))).getTime()
                if (isGrey) {
                    objStyle.background = '#f7f7f7'
                    objStyle.color = '#999'
                } else if (cellText.indexOf('未设置班次') > -1 || cellText.indexOf('未接班') > -1 || cellText.indexOf('未交班') > -1 || cellText.indexOf('交接班异常') > -1) {
                    objStyle.background = '#FDEFEF'
                    objStyle.color = '#e83636'
                } else if (cellText.indexOf('未排班') > -1 && cellText != '未排班' ){
                    objStyle.background = '#fef6ec'
                    objStyle.color = '#F09009'
                } else if (cellText == '未排班' ){
                    objStyle.background = '#fef6ec'
                    objStyle.color = '#333'
                } else {
                    objStyle.background = '#F6FBFF'
                    objStyle.color = '#333'
                }
            }
            return objStyle
        },
        abnormalFun (text) { // 异常颜色
            let isAbnormal = false
            let abnormal = ['未设置班次', '未接班', '未交班', '交接班异常']
            abnormal.map((item) => {
                if (text && text.indexOf(item) > -1) {
                    isAbnormal = true
                }
            })
            return isAbnormal
        },
        getComId() { // 获取comID&&请求列表
            setTimeout(() => {
                this.companyId = this.comId
                this.onchangeDate('W', '按周')
                this.commonDepartAll(this.companyId);
            },400)
        },
        isMonthWeekFun(e) { // 按月/按周按钮
            let params = { ...this.params };
            if (e == "按月") {
                let arrWeekMonth = [...this.arrWeekMonth];
                arrWeekMonth = getWeekMonthInfo(new Date())
                params.startDate = arrWeekMonth[0]["date"];
                params.endDate = arrWeekMonth[arrWeekMonth.length - 1]["date"];
                this.arrWeekMonth = arrWeekMonth
            }
            if (e == "按周") {
                let arrWeekDate = [...this.arrWeekDate];
                arrWeekDate = getWeekDateInfo(new Date())
                params.startDate = arrWeekDate[0]["date"];
                params.endDate = arrWeekDate[arrWeekDate.length - 1]["date"];
                this.arrWeekDate = arrWeekDate
            }
            this.tableDate = new Date(params.startDate)
            params.page = 1
            params.pageSize = 10
            this.params = params;
            this.getAttendanceList();
        },
        onchangeDate(e, type) { // type  -- M月 -- W周
            let params = { ...this.params };
            if (type == "M") {
                let arrWeekMonth;
                arrWeekMonth = getWeekMonthInfo(e);
                params.startDate = arrWeekMonth[0]["date"];
                params.endDate = arrWeekMonth[arrWeekMonth.length - 1]["date"];
                this.arrWeekMonth = arrWeekMonth;
            }
            if (type == "W") {
                let arrWeekDate;
                arrWeekDate = getWeekDateInfo(e);
                params.startDate = arrWeekDate[0]["date"];
                params.endDate = arrWeekDate[arrWeekDate.length - 1]["date"];
                this.arrWeekDate = arrWeekDate;
            }
            params.page = 1
            params.pageSize = 10
            this.params = params;
            this.getAttendanceList()
        },
        commonFun () { // 筛选方法
            this.params.page = 1
            this.params.pageSize = 10
            this.getAttendanceList()
        },
        resetBtn () { // 筛选方法
            this.params = {
                startDate: '',
                endDate: '',
                page: 1,
                pageSize: 10,
                userDepartId: [],
                nickName: '',
                reportDate: DateTransform(new Date()),
            }
            this.tableRadio = "按周";
            this.tableDate = new Date()
            this.onchangeDate('W', '按周')
            this.getAttendanceList()
        },
        async commonDepartAll(id) { // 部门 下拉
            let resData = (await commonDepartAll({}, id)).data;
            this.selectDepart = resData ? resData : [];
        },
        async getAttendanceList () { // 获取出勤列表
            let params = {...this.params}
            let tableRadio = this.tableRadio
            let arrWeekDate = [...this.arrWeekDate]
            let arrWeekMonth = [...this.arrWeekMonth]
            if (tableRadio == '按周') {
                params.startDate = arrWeekDate[0]["date"];
                params.endDate = arrWeekDate[arrWeekDate.length - 1]["date"];
            } else {
                params.startDate = arrWeekMonth[0]["date"];
                params.endDate = arrWeekMonth[arrWeekMonth.length - 1]["date"];
            }
            params.companyId = this.companyId
            if (params.userDepartId && params.userDepartId.length) {
                params.userDepartId = params.userDepartId[params.userDepartId.length -1]
            }
            if (params.reportDate) {
                delete params.reportDate
            }
            this.loading = true
            let { data } = await getAttendanceList(params)
            this.loading = false
            data.allList.map((item) => {
                item.schedulingRes.map((itemTwo, indexTwo) => {
                    let isGrey = false
                    if (tableRadio == '按周') {
                        arrWeekDate.map((itemThree, indexThree) => {
                            if (indexThree == indexTwo) {
                                isGrey = (new Date(itemThree.date)).getTime() > (new Date(DateTransform(new Date()))).getTime()
                            }
                        })
                    } else {
                        arrWeekMonth.map((itemThree, indexThree) => {
                            if (indexThree == indexTwo) {
                                isGrey = (new Date(itemThree.date)).getTime() > (new Date(DateTransform(new Date()))).getTime()
                            }
                        })
                    }
                    if (itemTwo != '未排班' && !isGrey) {
                        item.schedulingRes[indexTwo] = `${itemTwo}\n未接班`
                    }
                })
            })
            if (tableRadio == '按周') {
                arrWeekDate.map((item, index) => {
                    data.list.map((itemTwo) => {
                        data.allList.map((itemThree) => {
                            if (item.date == itemTwo.createTime && itemTwo.adminId == itemThree.adminId) {
                                if (itemTwo.frequencyName == '未设置班次') {
                                    // itemThree.schedulingRes[index] = `${itemTwo.frequencyName}`
                                    itemThree.schedulingRes[index] = `未排班\n${itemTwo.frequencyNameRe || ''}`
                                } else {
                                     if (itemTwo.frequencyNameRe) {
                                        itemThree.schedulingRes[index] = `${itemTwo.frequencyName}\n${itemTwo.frequencyNameRe || ''}`
                                    } else {
                                        itemThree.schedulingRes[index] = `${itemTwo.frequencyName}`
                                    }
                                    // itemThree.schedulingRes[index] += `\n${itemTwo.frequencyName}`
                                }
                            }
                        })
                    })
                })
            } else {
                arrWeekMonth.map((item, index) => {
                    data.list.map((itemTwo) => {
                        data.allList.map((itemThree) => {
                            if (item.date == itemTwo.createTime && itemTwo.adminId == itemThree.adminId) {
                                if (itemTwo.frequencyName == '未设置班次') {
                                    // itemThree.schedulingRes[index] = `${itemTwo.frequencyName}`
                                    itemThree.schedulingRes[index] = `未排班\n${itemTwo.frequencyNameRe || ''}`
                                } else {
                                    if (itemTwo.frequencyNameRe) {
                                        itemThree.schedulingRes[index] = `${itemTwo.frequencyName}\n${itemTwo.frequencyNameRe || ''}`
                                    } else {
                                        itemThree.schedulingRes[index] = `${itemTwo.frequencyName}`
                                    }
                                    // itemThree.schedulingRes[index] += `\n${itemTwo.frequencyName}`
                                    // itemThree.schedulingRes[index] = `${itemTwo.frequencyName}\n${itemTwo.frequencyNameRe || ''}`
                                }
                            }
                        })
                    })
                })
            }
            this.tableData = data.allList
            this.pagination.total = data.total
        },
        pageFun(e, type) { // 分页
            if (type == "pageSize") {
                this.params.pageSize = e;
            } else {
                this.params.page = e;
            }
            this.getAttendanceList()
        },
    }
}
</script>
<style lang="less" scoped>
.attendance-container {
    text-align: left;
    .SummaryItem-border {
        position: relative;
        &::after {
            position: absolute;
            content: ' ';
            display: block;
            width: 0;
            height: 50%;
            right: 0;
            top: 20%;
            bottom: 0;
            margin: auto;
            border-right: 1px solid #ccc;
        }
    }
    .head-main {
        position: relative;
        &::after {
            display: flex;
            content: ' ';
            position: absolute;
            height: 1px;
            width: 120%;
            background: #f2f4f5;
            bottom: 0;
            left: -10%;
        }
    }
    /deep/.testClass {
        background: #333;
    }
    /deep/.el-table thead.is-group th {
        background: #fff;
    }
    .calculation-screen-container {
        margin-left: 16px;
        height: 80px;
        display: flex;
        align-items: center;
        .common-input-container {
            margin-bottom: 0;
        }
        .date {
            margin-left: 16px;
        }
    }
    .grey-line {
        height: 16px;
        width: 120%;
        margin: 0 0 0 -10%;
        background: #eee;
    }
    .title {
        box-sizing: border-box;
        padding: 6px 16px 22px;
        height: 56px;
        color: #333;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &::after {
            display: flex;
            content: ' ';
            position: absolute;
            height: 1px;
            width: 120%;
            background: #f2f4f5;
            bottom: 0;
            left: -10%;
        }
    }
}
</style>